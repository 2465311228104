import React from 'react';
import { Link } from 'react-router-dom';
import HappyImage from '../images/happy.png';

export default function Navbar() {
  return (
    <nav>
      <Link to='/'>
        <div className='flex items-center h-8 mb-2 sm:mb-8'>
          <img src={HappyImage} alt='TeacherBud Logo' className='h-full' />
          <div className='text-2xl font-bold text-gray-900 md:text-4xl lg:text-4xl'>
            <span className='text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400'>
              Teacher
            </span>
            Bud
          </div>
        </div>
      </Link>
    </nav>
  );
}
