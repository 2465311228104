import React from 'react';
import useSound from 'use-sound';
import ConfettiExplosion from 'react-confetti-explosion';
import successSound from '../sounds/success-jingle.mp3';
import failureSound from '../sounds/failure-horn.mp3';
import explosionSound from '../sounds/explosion.mp3';
import swapSound from '../sounds/swap.mp3';
import magicSound from '../sounds/magic-chime.mp3';
import UhOhSound from '../sounds/uh-oh.mp3';

const GridBox = ({ label, value, onClick, isRevealed }) => {
  const [isExploding, setIsExploding] = React.useState(false);
  const [playSuccess] = useSound(successSound, { volume: 1 });
  const [playFailure] = useSound(failureSound, { volume: 1 });
  const [playExplosion] = useSound(explosionSound, { volume: 1 });
  const [playSwap] = useSound(swapSound, { volume: 1 });
  const [playMagic] = useSound(magicSound, { volume: 1 });
  const [playUhOh] = useSound(UhOhSound, { volume: 1 });

  const handleClick = () => {
    if (isRevealed) return;

    switch (value) {
      case '💎':
      case '❤️':
        playSuccess();
        break;
      case '💩':
        playFailure();
        break;
      case '💣':
        playExplosion();
        break;
      case '🔫':
        playUhOh();
        break;
      case '🪷':
        playMagic();
        break;
      case '🔄':
        playSwap();
        break;
      case '⭐':
        playSuccess();
        setIsExploding(true);
        setTimeout(() => {
          setIsExploding(false);
        }, 1000);
        break;
      default:
        break;
    }

    onClick();
  };

  return (
    <div>
      {isExploding && <ConfettiExplosion />}
      <button
        onClick={handleClick}
        disabled={isRevealed}
        className={`w-12 h-12 m-2 sm:m-3 sm:w-28 sm:h-28 bg-gradient-to-b text-green-500 font-semibold rounded-2xl
 ${
   isRevealed
     ? value === '💣'
       ? 'bg-gradient-to-b from-rose-100 to-rose-200 text-2xl sm:text-6xl shadow-inner border-none'
       : 'bg-gradient-to-b from-slate-50 to-slate-100 text-2xl sm:text-6xl shadow-inner border-none'
     : 'text-2xl sm:text-6xl from-emerald-50 to-emerald-100 shadow-emerald-400 shadow-sm border-b-4 border-emerald-200 hover:shadow-lg hover:animate-wiggle transition-all duration-500'
 }`}
      >
        {isRevealed ? value : label}
      </button>
    </div>
  );
};

export default GridBox;
