import React from 'react';

const Button = ({ onClick, className, text, hoverClass, sizeClass }) => {
  const baseClass = 'rounded-md shadow-md';
  const defaultClass = 'bg-sky-500 text-white font-bold';
  const defaultHoverClass = 'hover:bg-sky-600';
  const defaultSizeClass = 'p-2 text-base';

  const appliedClass = className || defaultClass;
  const appliedHoverClass = hoverClass || defaultHoverClass;
  const appliedSizeClass = sizeClass || defaultSizeClass;

  return (
    <button
      onClick={onClick}
      className={`${baseClass} ${appliedClass} ${appliedHoverClass} ${appliedSizeClass}`}
    >
      {text}
    </button>
  );
};

export default Button;
