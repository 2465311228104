import React, { useState } from 'react';
import GridBox from '../components/GridBox';
import Button from '../components/Button';
import MyModal from '../components/MyModal';

const emojis = ['💣', '💎', '💩', '⭐', '❤️', '🔄', '🪷', '🔫'];
const labels = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
];

// Generate a random index
const randomIndex = () => Math.floor(Math.random() * emojis.length);

const initGrid = () => {
  let labelIndex = 0;
  // Create a grid of 5 X 5, filled with random emojis
  return Array(5)
    .fill(0)
    .map((_) =>
      Array(5)
        .fill(0)
        .map(() => ({
          label: labels[labelIndex++],
          value: emojis[randomIndex()],
          isRevealed: false,
        }))
    );
};

const BombGame = () => {
  const [resetConfirm, setResetConfirm] = useState(false);
  const [btnText, setBtnText] = useState('Reset');
  const [btnColor, setBtnColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [grid, setGrid] = useState(initGrid());

  const handleReset = () => {
    if (!resetConfirm) {
      setBtnText('Sure?');
      setBtnColor('bg-red-500 text-white font-bold');
      setHoverColor('hover:bg-red-600');
      setResetConfirm(true);
    } else {
      reloadGame();
      setBtnText('Reset');
      setBtnColor('');
      setHoverColor('');
      setResetConfirm(false);
    }
  };

  const onBoxClick = (i, j) => {
    setGrid((prev) => {
      const copy = [...prev];
      copy[i][j].isRevealed = true;
      return copy;
    });
  };

  const reloadGame = () => {
    setGrid(initGrid());
  };

  return (
    <div className='flex flex-col items-center justify-start mt-10 h-screen'>
      <div>
        {grid.map((row, i) => (
          <div className='flex' key={i}>
            {' '}
            {/* A row */}
            {row.map(({ label, value, isRevealed }, j) => (
              <GridBox
                key={j}
                label={label}
                value={value}
                onClick={() => onBoxClick(i, j)}
                isRevealed={isRevealed}
              />
            ))}
          </div>
        ))}
        <div className='flex justify-center mt-4 space-x-4'>
          <Button
            onClick={handleReset}
            text={btnText}
            className={btnColor}
            hoverClass={hoverColor}
          />
          <Button
            onClick={handleOpen}
            className='bg-slate-500 text-white font-bold'
            hoverClass='hover:bg-slate-600'
            text='Rules'
          />
        </div>

        <MyModal title='Bomb Game Rules' isOpen={isOpen} onClose={handleClose}>
          <p>💣 = your score is now 0</p>
          <p>💎 = you earn 2 scores</p>
          <p>💩 = you lose 5 scores</p>
          <p>⭐ = you earn 10 scores</p>
          <p>❤️ = you earn 5 score</p>
          <p>🔄 = swap your score any other team</p>
          <p>🪷 = choose a team and give them 3 scores</p>
          <p>🔫 = choose a team and take away their score</p>
        </MyModal>
      </div>
    </div>
  );
};

export default BombGame;
