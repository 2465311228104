import React, { useState, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import useSound from 'use-sound';
import successSound from '../sounds/success-jingle.mp3';
import UhOhSound from '../sounds/uh-oh.mp3';
import swapSound from '../sounds/swap.mp3';
import failureSound from '../sounds/failure-horn.mp3';

export default function MysteryBox({
  isRevealed,
  currentItem,
  showConfetti,
  onIsRevealedChange,
  onCurrentItemChange,
}) {
  const items = ['-10', '-5', '+5', '+10', '+100', '💩', '🔄', '⭐', '🔥'];
  const [playSuccess] = useSound(successSound, { volume: 1 });
  const [playUhOh] = useSound(UhOhSound, { volume: 1 });
  const [playSwap] = useSound(swapSound, { volume: 1 });
  const [playFailure] = useSound(failureSound, { volume: 1 });
  const [textColor, setTextColor] = useState('text-gray-900');

  const boxClass = isRevealed
    ? 'bg-gradient-to-tr from-slate-100 to-slate-50'
    : 'bg-gradient-to-tr from-slate-900 to-slate-600';

  useEffect(() => {
    switch (currentItem) {
      case '-5':
      case '-10':
      case '-15':
        setTextColor('text-rose-500');
        playUhOh();
        break;
      case '+5':
      case '+10':
      case '+100':
      case '🔥':
        setTextColor('text-green-500');
        playSuccess();
        break;
      case '🔄':
      case '⭐':
        playSwap();
        break;
      case '💩':
        playFailure();
        break;
      default:
        setTextColor('text-gray-900');
    }
  }, [currentItem, playSuccess, playUhOh, playSwap, playFailure]);

  function handleOpenBox() {
    if (!isRevealed) {
      onIsRevealedChange(true);
      onCurrentItemChange('');
      const randomItem = items[Math.floor(Math.random() * items.length)];
      setTimeout(() => {
        onCurrentItemChange(randomItem);
      }, 0);
    }
  }

  return (
    <div className='flex justify-center items-center' onClick={handleOpenBox}>
      <div
        id='mystery-box'
        className={`sm:w-128 sm:h-128 w-80 h-80 border-8 border-amber-400 shadow-md rounded-2xl flex flex-col justify-center items-center text-9xl font-bold hover:cursor-pointer hover:shadow-xl transition-all duration-500 ${boxClass}`}
      >
        <span id='item'>
          {isRevealed ? (
            <span className={textColor}>{currentItem}</span>
          ) : (
            <span className='text-gray-100 text-9xl font-bold'>?</span>
          )}
        </span>
        {showConfetti && <ConfettiExplosion />}
      </div>
    </div>
  );
}
