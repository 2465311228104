import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import BombGame from './games/BombGame';
import MysteryBoxGame from './games/MysteryBoxGame';
import GetRandom from './tools/GetRandom';

const routeData = [
  {
    path: '/bomb-game',
    component: <BombGame />,
    title: 'Bomb Game',
    emoji: '💣',
  },
  {
    path: '/mystery-box',
    component: <MysteryBoxGame />,
    title: 'Mystery Box',
    emoji: '📦',
  },
  {
    path: '/get-random',
    component: <GetRandom />,
    title: 'Get Random',
    emoji: '🎲',
  },
];

function App() {
  return (
    <div className='mx-auto p-4'>
      <Router>
        <Navbar />
        <Routes>
          <Route
            path='/'
            element={
              <>
                {routeData.map(({ path, title, emoji }) => (
                  <Link key={path} to={path} className='block'>
                    <div className='max-w-md mx-auto bg-amber-400 rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5 p-8 flex items-center hoverable'>
                      <div className='text-6xl scaling-emoji'>{emoji}</div>
                      <div className='uppercase tracking-wide text-3xl text-slate-800 font-semibold ml-4'>
                        {title}
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            }
          />
          {routeData.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
