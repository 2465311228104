import { useState, useEffect } from 'react';
import Button from '../components/Button';
import MyModal from '../components/MyModal';
import MysteryBox from '../components/MysteryBox';

export default function MysteryBoxGame() {
  const [isRevealed, setIsReavealed] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (isRevealed && currentItem === '⭐') {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
  }, [isRevealed, currentItem]);

  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex flex-col items-center mt-8'>
        <div className='sm:text-5xl text-4xl font-bold mb-4 text-gray-400'>
          KEEP THE BOX?
        </div>
        <MysteryBox
          isRevealed={isRevealed}
          currentItem={currentItem}
          showConfetti={showConfetti}
          onIsRevealedChange={setIsReavealed}
          onCurrentItemChange={setCurrentItem}
        />
        <div className='sm:text-5xl text-4xl font-bold mt-4 text-gray-400'>
          GIVE THE BOX?
        </div>
        <div className='flex justify-center mt-4 space-x-4'>
          <Button onClick={() => setIsReavealed(false)} text='Reset' />
          <Button
            onClick={handleOpen}
            className='bg-slate-500 text-white font-bold'
            hoverClass='hover:bg-slate-600'
            text='Rules'
          />
        </div>
      </div>
      <MyModal title='Mystery Box Rules' isOpen={isOpen} onClose={handleClose}>
        <p className='font-bold'>
          When it's your turn, you can choose to keep the box or give it away.
        </p>
        <p>
          💩 = the box owner's score is set to 0 (negative score remains
          unchanged)
        </p>
        <p>
          🔄 = scores are swapped regardless of whether the box is kept or given
          away
        </p>
        <p>⭐ = the box owner can align their score with any other team</p>
        <p>
          🔥 = double the score for the box owner (if the score is negative,
          reset it to 0)
        </p>
      </MyModal>
    </div>
  );
}
