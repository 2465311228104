import React from 'react';

function MyModal({ title, children, isOpen, onClose }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
          <div>
            <div className='mt-3 text-center sm:mt-5'>
              <h3 className='text-3xl leading-6 font-medium text-gray-900 mb-7'>
                {title}
              </h3>
              <div className='mt-2'>
                <p className='text-base text-gray-500 text-left'>{children}</p>
              </div>
            </div>
          </div>
          <div className='mt-5 sm:mt-6'>
            <span className='flex w-full rounded-md shadow-sm'>
              <button
                type='button'
                onClick={onClose}
                className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5'
              >
                Close
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyModal;
