import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const GetRandom = () => {
  const [list, setList] = useState(() => {
    const items = localStorage.getItem('items');
    return items ? JSON.parse(items) : [];
  });

  const [input, setInput] = useState('');
  const [count, setCount] = useState(1);
  const [showField, setShowField] = useState(true);
  const [randomItems, setRandomItems] = useState([]);
  const [isRandomClicked, setIsRandomClicked] = useState(false);

  useEffect(() => {
    localStorage.setItem('items', JSON.stringify(list));
  }, [list]);

  const enterItems = (e) => {
    e.preventDefault();
    const nonEmptyLines = input
      .split('\n')
      .filter((line) => line.trim() !== '');
    setList(nonEmptyLines);
    setIsRandomClicked(false);
    setShowField(false);
  };

  const getRandom = () => {
    setIsRandomClicked(true);
    const items = [...list];
    const randoms = [];

    for (let i = 0; i < count; i++) {
      const ri = Math.floor(Math.random() * items.length);
      randoms.push(items[ri]);
      items.splice(ri, 1);
    }
    setRandomItems(randoms);
  };

  const clearList = () => {
    setList([]);
    setShowField(true);
  };

  return (
    <div className='flex flex-col items-center justify-start mt-10 h-screen'>
      <div className='w-3/4 h-auto'>
        {showField && (
          <form onSubmit={enterItems} className='space-y-6'>
            <textarea
              className='w-full h-48 p-4 text-xl border-4 border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-500'
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Enter items on separate lines'
            />
            <Button type='submit' sizeClass='w-full p-3' text='Enter' />
          </form>
        )}

        {!showField && (
          <div className='space-y-3'>
            {isRandomClicked && (
              <div className='text-sky-800 text-8xl font-semibold text-center mb-20'>
                {randomItems.map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
            )}

            <div className='flex space-x-3'>
              <input
                type='number'
                min='1'
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className='w-1/3 p-2 text-base border-4 border-gray-200 rounded-md focus:outline-none focus:border-blue-500'
              />
              <Button
                onClick={getRandom}
                sizeClass='w-2/3 p-3'
                text='Get Random'
              />
            </div>
            <Button
              onClick={clearList}
              sizeClass='w-full p-3'
              className='bg-gray-300 text-gray-500 font-bold'
              hoverClass='hover:bg-gray-400'
              text={
                <>
                  <FontAwesomeIcon icon={faPenToSquare} className='mr-2' />
                  Edit List
                </>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GetRandom;
